import React, { useState } from "react"
import css from "@styled-system/css"
import { AnimatePresence, motion } from "framer-motion"
import ModalVideo from 'react-modal-video'

import { mdiClose } from "@mdi/js"

import {
  Box,
  Button,
  Column,
  Container,
  DunkText,
  FadeText,
  Flex,
  H1,
  Text,
} from "components"

import mouseCursor from "../../../static/images/mouseCursor.png"

const mqIphoneX = `@media only screen and (min-device-width : 375px) and (max-device-width : 812px) and (-webkit-device-pixel-ratio : 3) and (orientation : portrait)`

function SecondSection({
  buttonText,
  title,
  subtitle,
  image,
  video,
  popupVideo
}) {
  const [isOpen, setOpen] = useState(false)
  return (
    <Flex
      as="section"
      position="relative"
      flexDirection="column"
      justifyContent={["flex-end", "center"]}
      height={["100vh"]}
      pb={[5, 0]}
      css={css({
        background:
          !video &&
          "linear-gradient(0deg, rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0.15)), url(" +
          image +
          ")",
        backgroundSize: "cover",
        backgroundPosition: "center",
        [mqIphoneX]: {
          height: "calc(100vh - 113px)",
        },
        zIndex: 1
      })}
    >
      {video && (
        <div
          css={css({
            position: "absolute",
            left: 0,
            top: 0,
            width: "100%",
            height: "100%",
            zIndex: -1,
          })}
        >
          <video
            width="400"
            autoPlay="autoplay"
            loop="loop"
            muted
            defaultMuted
            playsInline
            css={css({
              position: "absolute",
              left: 0,
              top: 0,
              width: "100%",
              height: "100%",
              objectFit: "cover",
              zIndex: -1,
            })}
          >
            <source src={video} type="video/mp4" />
            Your browser does not support HTML5 video.
          </video>
          <div
            css={css({
              position: "absolute",
              left: 0,
              top: 0,
              width: "100%",
              height: "100%",
              background:
                "linear-gradient(0deg, rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0.15))",
            })}
          />
        </div>
      )}
      <AnimatePresence>
        {(
          <motion.div
            variants={{
              initial: {
                opacity: 0,
              },
              enter: {
                opacity: 1,
                transition: {
                  duration: 0.7,
                },
              },
              exit: {
                opacity: 0,
                transition: { duration: 0.7 },
              },
            }}
            initial="initial"
            animate="enter"
            exit="exit"
          >
            <Container minHeight={["33%", 0]} pt={[96, 120]}>
              <Column right>
                <H1 as="h1" color="textReverse">
                  <DunkText
                    lines={title ? title.split("\n") : []}
                    animated={true}
                  />
                </H1>
                <FadeText delay={1}>
                  <Text
                    children={subtitle}
                    mt={[3, 4]}
                    fontFamily="condensed"
                    fontSize={[5, 6]}
                    color="textReverse"
                  />
                  {buttonText && (
                    <Box
                      mt={[3, 4]}
                      css={{
                        display: "block",
                      }}
                    >
                      <Button
                        children={buttonText}
                        rel="noopener noreferrer"
                        css={{ pointerEvents: "all" }}
                        onClick={() => setOpen(true)}
                      />
                    </Box>
                  )}
                </FadeText>
              </Column>
            </Container>
          </motion.div>
        )}
      </AnimatePresence>
      {isOpen && (
        <div
          css={css({
            position: "absolute",
            left: 0,
            top: 0,
            width: "100%",
            height: "100%",
            zIndex: 0,
          })}
        >
          <ModalVideo channel='youtube' autoPlay isOpen={isOpen} videoId={popupVideo} onClose={() => setOpen(false)} />
        </div>
      )}

    </Flex>
  )
}

export default SecondSection
