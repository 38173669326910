import React, { useState } from "react"
import css from "@styled-system/css"
import { AnimatePresence, motion } from "framer-motion"

import { mdiClose } from "@mdi/js"

import {
  Box,
  Button,
  Column,
  Container,
  DunkText,
  FadeText,
  Flex,
  H1,
  H6,
  Icon,
  Text,
} from "components"

import mouseCursor from "../../../static/images/mouseCursor.png"

const mqIphoneX = `@media only screen and (min-device-width : 375px) and (max-device-width : 812px) and (-webkit-device-pixel-ratio : 3) and (orientation : portrait)`

function FeatureSection({
  buttonText,
  title,
  subtitle,
  image,
  video,
  prompt,
  panorama,
}) {
  const [showPanorama, setShowPanorama] = useState(false)
  return (
    <Flex
      as="section"
      position="relative"
      flexDirection="column"
      justifyContent={["flex-end", "center"]}
      height={["100vh"]}
      pb={[5, 0]}
      css={css({
        cursor: panorama && ["", `url(${mouseCursor}), auto`],
        background:
          !video &&
          "linear-gradient(0deg, rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0.15)), url(" +
          image +
          ")",
        backgroundSize: "cover",
        backgroundPosition: "center",
        [mqIphoneX]: {
          height: "calc(100vh - 113px)",
        },
        "@media screen and (max-width: 1280px)": {
          pointerEvents: "none",
        },
        "@media (hover: none)": {
          pointerEvents: "none",
        },
      })}
      onClick={() => {
        if (panorama) {
          setShowPanorama(true)
        }
      }}
    >
      {video && (
        <div
          css={css({
            position: "absolute",
            left: 0,
            top: 0,
            width: "100%",
            height: "100%",
            zIndex: -1,
          })}
        >
          <video
            width="400"
            autoplay="autoplay"
            loop="loop"
            muted
            defaultMuted
            playsInline
            css={css({
              position: "absolute",
              left: 0,
              top: 0,
              width: "100%",
              height: "100%",
              objectFit: "cover",
              zIndex: -1,
            })}
          >
            <source src={video} type="video/mp4" />
            Your browser does not support HTML5 video.
          </video>
          <div
            css={css({
              position: "absolute",
              left: 0,
              top: 0,
              width: "100%",
              height: "100%",
              background:
                "linear-gradient(0deg, rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0.15))",
            })}
          />
        </div>
      )}
      {panorama && showPanorama && (
        <>
          <Box
            height={120}
            top={0}
            css={css({
              bg: "white",
              position: "absolute",
              left: 0,
              width: "100%",
              height: "100%",
            })}
          />
          <Box
            height={["100vh", "calc(100vh - 120px)"]}
            top={[0, 120]}
            css={css({
              position: "absolute",
              left: 0,
              width: "100%",
              height: "100%",
            })}
          >
            <iframe
              src={panorama}
              title="heroPanorama"
              name="Falk360"
              width="100%"
              height={"100%"}
              frameborder="0"
              allow="fullscreen; accelerometer; gyroscope; magnetometer; vr"
              allowFullScreen="false"
              scrolling="no"
              seamless="seamless"
            />
          </Box>
        </>
      )}
      <AnimatePresence>
        {showPanorama && (
          <motion.div
            variants={{
              initial: {
                opacity: 0,
              },
              enter: {
                opacity: 1,
                transition: {
                  duration: 0.3,
                  delay: 0.7,
                },
              },
              exit: {
                opacity: 0,
                transition: { duration: 0.3 },
              },
            }}
            initial="initial"
            animate="enter"
            exit="exit"
          >
            <Flex
              as="button"
              position="absolute"
              top={[100, 196]}
              right={48}
              zIndex={20}
              height={48}
              width={48}
              bg="primary"
              justifyContent="center"
              alignItems="center"
              css={{
                border: "none",
                cursor: "pointer",
              }}
              onClick={e => {
                setShowPanorama(false)
                e.preventDefault()
                e.stopPropagation()
              }}
            >
              <Icon symbol={mdiClose} color="white" size={2} />
            </Flex>
          </motion.div>
        )}
      </AnimatePresence>
      <AnimatePresence>
        {!showPanorama && (
          <motion.div
            variants={{
              initial: {
                opacity: 0,
              },
              enter: {
                opacity: 1,
                transition: {
                  duration: 0.7,
                },
              },
              exit: {
                opacity: 0,
                transition: { duration: 0.7 },
              },
            }}
            initial="initial"
            animate="enter"
            exit="exit"
          >
            <Container minHeight={["33%", 0]} pt={[96, 120]}>
              <Column left>
                <H1 as="h1" color="textReverse">
                  <DunkText
                    lines={title ? title.split("\n") : []}
                    animated={true}
                  />
                </H1>
                <FadeText delay={1}>
                  <Text
                    children={subtitle}
                    mt={[3, 4]}
                    fontFamily="condensed"
                    fontSize={[5, 6]}
                    color="textReverse"
                  />
                  {buttonText && panorama && (
                    <Box
                      mt={[3, 4]}
                      css={{
                        display: "block",
                        "@media screen and (min-width: 1280px)": {
                          display: "none",
                        },
                        "@media (hover: none)": {
                          display: "block",
                        },
                      }}
                    >
                      <Button
                        children={buttonText}
                        as={"a"}
                        href={panorama}
                        target="_blank"
                        rel="noopener noreferrer"
                        css={{ pointerEvents: "all" }}
                        onClick={event => {
                          event.stopPropagation()
                        }}
                      />
                    </Box>
                  )}
                </FadeText>
              </Column>
            </Container>
            {/* {prompt && (
              <Flex
                position="absolute"
                bottom="48px"
                left="50%"
                display={["none", "flex"]}
                flexDirection="column"
                alignItems="center"
                css={css({ transform: "translateX(-50%)" })}
              >
                <H6
                  children={prompt}
                  mb={2}
                  textAlign="center"
                  color="textReverse"
                />
                <svg
                  width={29}
                  height={10}
                  fill="none"
                  css={css({
                    verticalAlign: "top",
                  })}
                >
                  <path
                    d="M.25.43l14.12 8.15L28.48.43"
                    stroke="#fff"
                    strokeMiterlimit={10}
                  />
                </svg>
              </Flex>
            )} */}
          </motion.div>
        )}
      </AnimatePresence>
    </Flex>
  )
}

export default FeatureSection
